<template>
  <div>
    <a-card style="height: calc(50vh - 48px);">
      <div slot="title" style="height: 24px;">
        <span>物料入库任务提醒</span>
        <!-- <a-select v-model="taskType" size="small" @change="changeTask"
          style="width: 160px; float: right;">
          <a-select-option value="purchase">采购入库</a-select-option>
          <a-select-option value="purchaseReturn">采购退货</a-select-option>
          <a-select-option value="sales">销售出库</a-select-option>
          <a-select-option value="salesReturn">销售退货</a-select-option>
        </a-select> -->
      </div>
      
      <a-table :columns="columns" :data-source="items" size="small" :pagination="false" :loading="loading"
        :scroll="{y: tableHeight}">

        <template slot="details" slot-scope="text, record">
          <a-tooltip :title="generateDetails(record)">
            <span
              style="display: inline-block; width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
              {{ generateDetails(record).slice(0, 30) }}
            </span>
          </a-tooltip>
        </template>
        <div slot="action" slot-scope="value, item">
          <a-button type="link" @click="viewTask(item)">入库</a-button>
        </div>
      </a-table>
      <div style="text-align: center; margin-top: 16px;">
        <a-pagination v-model="searchForm.page" :total="totalRows" :pageSize="perPage" show-less-items
          @change="changePage" />
      </div>
    </a-card>
  </div>
</template>

<script>
  import { stockInList } from '@/api/system'
  import moment from 'moment'

  export default {
    name: 'WarehouseTask',
    data() {
      return {
        moment,
        taskType: 'purchase',
        items: [],
        perPage: 16,
        totalRows: 0,
        searchForm: { page: 1, page_size: 10, is_done: false },
        loading: false,
        columns: [
        {
          title: "详情",
          dataIndex: "details",
          width: '250px',
          scopedSlots: { customRender: 'details' },
        },
        {
          title: "期望到达",
          dataIndex: "eta",
          customRender: (value, record) => {
            const today = new Date().toISOString().split('T')[0];
            const isToday = value === today;
            const isPast = value < today;

            const className = isToday ? 'is-today' : isPast ? 'is-past' : '';
            return {
              children: <div class={className}>{value}</div>,
              attrs: {},
            };
          },
        },
        {
          title: "处理日期",
          dataIndex: "create_time",
          width: 170,
        }, {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
         
        },
        ],
        tableHeight: window.innerHeight / 2 - 240,
      };
    },
    methods: {
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        stockInList(this.searchForm).then(resp => {
          this.totalRows = resp.count;
          this.items = resp.results;
        }).finally(() => {
          this.loading = false;
        });
      },
      viewTask(item) {
          this.$router.push({ path: '/warehousem/inStock_create', query: { id: item.id } });
      },
      changeTask() {
        this.searchForm.page = 1;
        this.list();
      },
      changePage(value) {
        this.searchForm.page = value;
        this.list();
      },
      generateDetails(item) {
      const results = item.stock_in_goods_items.map(innerItem => {
        const quantityUnit = [
          innerItem['stock_in_quantity'],
          innerItem['unit_name']
        ].filter(value => value != null).join('');  // 直接连接，不使用下划线

        const parts = [
          innerItem['goods_number'],
          innerItem['goods_name'],
          quantityUnit  // 把合并后的数量和单位作为一个部分
        ].filter(value => value != null);  // 继续过滤掉null和undefined

        return parts.join('_');  // 使用下划线连接字符串
      });
      return results.join(', ');
    },
    },
    mounted() {
      this.initialize();
    },
  }
</script>

<style scoped>
.is-today {
  background-color: yellow;
}

.is-past {
  background-color: red;
}
</style>